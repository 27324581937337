import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/js/dist/collapse.js";
import "bootstrap/js/dist/dropdown.js";
import "../styles/main.scss";

const revealEmailAddress = (element: Element) => {
    const encodedEmailAddress = element.getAttribute("data-eea");

    if (!encodedEmailAddress) {
        return;
    }

    const emailAddress = atob(encodedEmailAddress.replace(/-/g, "+").replace(/_/g, "/"));
    element.setAttribute("href", `mailto:${emailAddress}`);
};

for (const emailLink of document.querySelectorAll("a[data-eea]")) {
    emailLink.addEventListener("mouseover", () => revealEmailAddress(emailLink));
    emailLink.addEventListener("focus", () => revealEmailAddress(emailLink));
}
